// dependencies
import { Suspense, lazy } from 'react';
import { ROUTES } from '@constants';
import { LoginCallback } from '@okta/okta-react';
import { createBrowserRouter } from 'react-router-dom';
import { SecureRoutesWrapper } from './components/SecureRoutesWrapper';
import { AuthRoutesWrapper } from './components/AuthRoutesWrapper';
import { InitializeUserWrapper } from './components/InitializeUserWrapper';
import { InitializeSessionWrapper } from './components/InitializeSessionWrapper';
import { AppActivityIndicator } from './components/AppActivityIndicator';
import { BackLinkPageWrapper } from './components/BackLinkPageWrapper';
import { RedirectToCalendar } from './components/RedirectToCalendar';
import { RedirectToDealTab } from './components/RedirectToDealTab';
import { RestrictedRoutesWrapper } from './components/RestrictedRoutesWrapper';
import TabWrapper from './components/TabWrapper';
import ErrorRoute from './components/ErrorRoute';
import CheckUserFeatureWrapper from './components/CheckUserFeatureWrapper';
// shells
import { AuthShell } from './shells/AuthShell';
import { MainShell } from './shells/MainShell';
// pages
import { AddressBookPage } from './pages/AddressBookPage';
import { CalendarPage } from './pages/CalendarPage';
import { ChatPage } from './pages/ChatPage';
import { ChatChannelPage } from './pages/ChatChannelPage';
import { CreateChatPage } from './pages/CreateChatPage';
import { CreateGroupChatPage } from './pages/CreateGroupChatPage';
import { ChatViewMembers } from './pages/ChatViewMembers';
import { RenameGroupPage } from './pages/RenameGroupPage';
import { ChatEditGroupPage } from './pages/ChatEditGroupPage';
import { GroupsPage } from './pages/GroupsPage';
import { GroupPage } from './pages/GroupPage';
import { GroupTabsPage } from './pages/GroupTabsPage';
import { HomePage } from './pages/HomePage';
import { LearnRootPage } from './pages/LearnRootPage';
import { LearnPage } from './pages/LearnPage';
import { TipJarRootPage } from './pages/TipJarRootPage';
import { TipJarPage } from './pages/TipJarPage';
import { MemberGuidebookPage } from './pages/MemberGuidebookPage';
import { NetworksPage } from './pages/NetworksPage';
import { NetworkPage } from './pages/NetworkPage';
import { NetworkTabsPage } from './pages/NetworkTabsPage';
import { PartnerBenefitsPage } from './pages/PartnerBenefitsPage';
import { TigerPulsePage } from './pages/TigerPulsePage';
import { AssetAllocationPage } from './pages/AssetAllocationPage';
import { PoliciesPage } from './pages/PoliciesPage';
import { ProfilePage } from './pages/ProfilePage';
import { ReferAnAssociatePage } from './pages/ReferAnAssociatePage';
import { SettingsPage } from './pages/SettingsPage';
import { SignInPage } from './pages/SignInPage';
import { SignOutPage } from './pages/SignOutPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { SetNewPasswordPage } from './pages/SetNewPasswordPage';
import { StaffAndSupportPage } from './pages/StaffAndSupportPage';
import { VerifySignInPage } from './pages/VerifySignInPage';
import { ActivityIndicator } from '@ui-kit';
import { ContactPage } from './pages/ContactPage';
import EventPage from './pages/EventPage';
import { EditProfileActivitiesPage } from './pages/EditProfileActivitiesPage';
import MeetingPage from './pages/MeetingPage';
import { EditProfileBiographyPage } from './pages/EditProfileBiographyPage';
import { EditProfileDetailsPage } from './pages/EditProfileDetailsPage';
import { EditProfileEventsPage } from './pages/EditProfileEventsPage';
import { RequestNetworkPage } from './pages/RequestNetworkPage';
import { RequestNetworkConfirmationPage } from './pages/RequestNetworkConfirmationPage';
import { EditProfileInvestmentExpertisePage } from './pages/EditProfileInvestmentExpertisePage';
import { SelectTagsPage } from './pages/SelectTagsPage';
import { EditProfileExpertisePage } from './pages/EditProfileExpertisePage';
import { EditProfileInterestsPage } from './pages/EditProfileInterestsPage';
import { EditProfileInformationPage } from './pages/EditProfileInformationPage';
import { ProfileEventsPage } from './pages/ProfileEventsPage';
import { EditContactPage } from './pages/EditContactPage';
import { EditDietaryRestrictionsPage } from './pages/EditDietaryRestrictionsPage';
import { SearchResultsPage } from './pages/SearchResultsPage';
import { CommunityFilesPage } from './pages/CommunityFilesPage';
import { PostPage } from './pages/PostPage';
import { SupportFaqPage } from './pages/SupportFaqPage';
import { SupportAppTutorialsPage } from './pages/SupportAppTutorialsPage';
import { SupportRequestHelpPage } from './pages/SupportRequestHelpPage';
import { NotificationsSettingsPage } from './pages/NotificationsSettingsPage';
import { BlockUsersSettingsPage } from './pages/BlockUsersSettingsPage';
import { ReportContentPage } from './pages/ReportContentPage';
import { PostCreationPage } from './pages/PostCreationPage';
import { ChatUnPauseMembers } from './pages/ChatUnPauseMembers';
import { DealsTabsPage } from './pages/DealsTabsPage';
import NewDealPage from './pages/NewDealPage';
import DealsSettingsPage from './pages/DealsSettingsPage';
import NotificationsSettingsGroupPage from './pages/NotificationsSettingsGroupPage';
import DealFiltersPage from './pages/DealFiltersPage';
import { DealTabsPage } from './pages/DealTabsPage';
import { DealsRootPage } from './pages/DealsRootPage';
import EditDealPage from './pages/EditDealPage';
import EditThirdPartyDealPage from './pages/EditThirdPartyDealPage';
import { NetworkConnectionTabsPage } from './pages/NetworkConnectionTabsPage';
import { AllDealFiltersPage } from './pages/AllDealFiltersPage';
import { ChaptersPage } from './pages/ChaptersPage';
import { ChapterPage } from './pages/ChapterPage';
import { ChapterTabsPage } from './pages/ChapterTabsPage';
import { AppRouterContextProvider } from './AppRouterContext';
import { CreateNetworkConnectionPage } from './pages/CreateNetworkConnectionPage';
import { EditNetworkConnectionPage } from './pages/EditNetworkConnectionPage';
import NetworkConnectionFiltersPage from './pages/NetworkConnectionFiltersPage';
import { AssetAllocationInfoPage } from './pages/AssetAllocationInfoPage';
import { AssetAllocationNetworksPage } from './pages/AssetAllocationNetworksPage';
import { TrustedDevicePage } from './pages/TrustedDevicePage';
import { NewTipPage } from './pages/NewTipPage';
import { NewTipCategoriesPage } from './pages/NewTipCategoriesPage';
import { NewTipMatchesPage } from './pages/NewTipMatchesPage';
import { CreateTipPage } from './pages/CreateTipPage';
import { CreateTipReviewPage } from './pages/CreateTipReviewPage';
import { TipReviewPage } from './pages/TipReviewPage';
import TipPage from './pages/TipPage';

const signingInFallbackElement = <AppActivityIndicator />;
const loadingFallbackElement = <AppActivityIndicator title="Loading..." />;

// Lazy loaded routes.
const NewPostPage = lazy(() => import('./pages/NewPostPage/NewPostPage'));
const PostSettingsPage = lazy(() => import('./pages/PostSettingsPage/PostSettingsPage'));
const MeetingAttendanceRequestsPage = lazy(
	() => import('./pages/MeetingAttendanceRequestsPage/MeetingAttendanceRequestsPage'),
);

export const appRouter = createBrowserRouter([
	{
		errorElement: <ErrorRoute />,
		element: (
			<AuthRoutesWrapper loadingElement={loadingFallbackElement}>
				<Suspense fallback={signingInFallbackElement}>
					<AuthShell />
				</Suspense>
			</AuthRoutesWrapper>
		),

		children: [
			{
				path: ROUTES.signIn(),
				element: <SignInPage />,
			},
			{
				path: ROUTES.verifySignIn(),
				element: <VerifySignInPage />,
			},
			{
				path: ROUTES.resetPassword(),
				element: <ResetPasswordPage />,
			},
			{
				path: ROUTES.setNewPassword(),
				element: <SetNewPasswordPage />,
			},
			{
				path: ROUTES.trustedDevice(),
				element: <TrustedDevicePage />,
			},
		],
	},
	{
		path: ROUTES.thirdPartyDeal(),
		element: <EditThirdPartyDealPage />,
	},
	{
		path: ROUTES.signInCallback(),
		element: (
			<LoginCallback
				loadingElement={signingInFallbackElement}
				onAuthResume={() => appRouter.navigate(ROUTES.verifySignIn())}
			/>
		),
	},
	{
		path: ROUTES.signOut(),
		element: <SignOutPage />,
	},
	{
		element: (
			<SecureRoutesWrapper signingInFallback={signingInFallbackElement}>
				<InitializeUserWrapper>
					<InitializeSessionWrapper>
						<AppRouterContextProvider getAppRouter={() => appRouter}>
							<MainShell />
						</AppRouterContextProvider>
					</InitializeSessionWrapper>
				</InitializeUserWrapper>
			</SecureRoutesWrapper>
		),
		children: [
			{
				path: ROUTES.home(),
				element: <HomePage />,
			},
			{
				path: ROUTES.chat(),
				element: <ChatPage />,
				children: [
					{
						path: ROUTES.chatChannel(),
						element: <ChatChannelPage />,
					},
					{
						path: ROUTES.createChat(),
						element: <CreateChatPage />,
					},
					{
						path: ROUTES.createChatGroup(),
						element: <CreateGroupChatPage />,
					},
					{
						path: ROUTES.chatViewMembers(),
						element: <ChatViewMembers />,
					},
					{
						path: ROUTES.chatRenameGroup(),
						element: <RenameGroupPage />,
					},
					{
						path: ROUTES.chatEditGroup(),
						element: <ChatEditGroupPage />,
					},
					{
						path: ROUTES.chatUnPauseMembers(),
						element: <ChatUnPauseMembers />,
					},
				],
			},
			{
				path: ROUTES.networks(),
				element: (
					// Suspense is to wait until network tabs are loaded.
					<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
						<NetworksPage />
					</Suspense>
				),
				children: [
					{
						path: ROUTES.requestNetwork(),
						element: <RequestNetworkPage />,
					},
					{
						path: ROUTES.requestNetworkConfirmation(),
						element: (
							<TabWrapper>
								<RequestNetworkConfirmationPage />
							</TabWrapper>
						),
					},
					{
						path: ROUTES.networkFolder(),
						element: <CommunityFilesPage />,
					},
					{
						path: ROUTES.network(),
						element: <NetworkPage />,
						children: [
							{
								path: ROUTES.networkTab(),
								element: <NetworkTabsPage />,
							},
						],
					},
				],
			},
			{
				path: ROUTES.groups(),
				element: <GroupsPage />,
				children: [
					{
						path: ROUTES.chapters(),
						element: <ChaptersPage />,
					},
					{
						path: ROUTES.chapterFolder(),
						element: <CommunityFilesPage />,
					},
					{
						path: ROUTES.chapter(),
						element: <ChapterPage />,
						children: [
							{
								path: ROUTES.chapterTab(),
								element: <ChapterTabsPage />,
							},
						],
					},

					{
						path: ROUTES.groupFolder(),
						element: <CommunityFilesPage />,
					},
					{
						path: ROUTES.group(),
						element: <GroupPage />,
						children: [
							{
								path: ROUTES.groupTab(),
								element: <GroupTabsPage />,
							},
						],
					},
				],
			},
			{
				path: ROUTES.chapters(),
				element: <ChaptersPage />,
				children: [
					{
						path: ROUTES.chapterFolder(),
						element: <CommunityFilesPage />,
					},
					{
						path: ROUTES.chapter(),
						element: <ChapterPage />,
						children: [
							{
								path: ROUTES.chapterTab(),
								element: <ChapterTabsPage />,
							},
						],
					},
				],
			},
			{
				path: ROUTES.calendar(),
				element: <CalendarPage />,
				children: [
					{
						path: ROUTES.calendarEvent(),
						element: <EventPage />,
					},
					{
						path: ROUTES.calendarMeeting(),
						element: <MeetingPage />,
					},
				],
			},
			{
				path: '/events/:eventId',
				element: <RedirectToCalendar />, // redirects to "/calendar/events/:id"
			},
			{
				path: '/group_meetings/:eventId',
				element: <RedirectToCalendar />, // redirects to "/calendar/group_meetings/:id"
			},
			{
				path: ROUTES.viewEvent(),
				element: (
					<BackLinkPageWrapper limitedWidth>
						<EventPage />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.viewMeeting(),
				element: (
					<BackLinkPageWrapper>
						<MeetingPage scrollable={false} />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.learn(),
				element: (
					<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
						<LearnRootPage />
					</Suspense>
				),
				children: [
					{
						path: ROUTES.learnTab(),
						element: <LearnPage />,
					},
				],
			},
			{
				path: ROUTES.tipJar(),
				element: (
					<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
						<TipJarRootPage />
					</Suspense>
				),
				children: [
					{
						path: ROUTES.tipJarTab(),
						element: (
							<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
								<TipJarPage />
							</Suspense>
						),
					},
				],
			},
			{
				path: ROUTES.newTip(),
				element: <NewTipPage />,
			},
			{
				path: ROUTES.newTipCategories(),
				element: <NewTipCategoriesPage />,
			},
			{
				path: ROUTES.newTipMatches(),
				element: <NewTipMatchesPage />,
			},
			{
				path: ROUTES.createTip(),
				element: <CreateTipPage />,
			},
			{
				path: ROUTES.createTipReview(),
				element: <CreateTipReviewPage />,
			},
			{
				path: ROUTES.tipReview(),
				element: <TipReviewPage />,
			},
			{
				path: ROUTES.tipPage(),
				element: (
					<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
						<TipPage />
					</Suspense>
				),
			},
			{
				path: ROUTES.profile(),
				element: <ProfilePage />,
			},
			{
				path: ROUTES.addressBook(),
				element: <AddressBookPage />,
			},
			{
				path: ROUTES.referAssociate(),
				element: <ReferAnAssociatePage />,
			},
			{
				path: ROUTES.memberGuideBook(),
				element: <MemberGuidebookPage />,
			},
			{
				path: ROUTES.partnerBenefits(),
				element: <PartnerBenefitsPage />,
			},
			{
				path: ROUTES.tigerPulse(),
				element: <TigerPulsePage />,
			},
			{
				path: ROUTES.assetAllocation(),
				element: (
					<CheckUserFeatureWrapper featureName="asset_allocations">
						<AssetAllocationPage />
					</CheckUserFeatureWrapper>
				),
				children: [
					{
						path: ROUTES.assetAllocation(),
						element: <AssetAllocationNetworksPage />,
					},
					{
						path: ROUTES.assetAllocationNetwork(),
						element: <AssetAllocationInfoPage />,
					},
				],
			},
			{
				path: ROUTES.policies(),
				element: <PoliciesPage />,
			},
			{
				path: ROUTES.staffAndSupport(),
				element: <StaffAndSupportPage />,
			},
			{
				path: ROUTES.settings(),
				element: <SettingsPage />,
			},
			{
				path: ROUTES.notificationSettings(),
				element: (
					<BackLinkPageWrapper>
						<NotificationsSettingsPage />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.notificationSettingsGroup(),
				element: (
					<BackLinkPageWrapper>
						<NotificationsSettingsGroupPage />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.blockedUsersSettings(),
				element: (
					<BackLinkPageWrapper>
						<BlockUsersSettingsPage />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.memberProfile(),
				element: <ProfilePage />,
			},
			{
				path: ROUTES.contact(),
				element: <ContactPage />,
			},
			{
				path: ROUTES.profileEvents(),
				element: <ProfileEventsPage />,
			},
			{
				path: ROUTES.editProfileActivities(),
				element: <EditProfileActivitiesPage />,
			},
			{
				path: ROUTES.editProfileBiography(),
				element: <EditProfileBiographyPage />,
			},
			{
				path: ROUTES.editProfileDetails(),
				element: <EditProfileDetailsPage />,
			},
			{
				path: ROUTES.editProfileEvents(),
				element: <EditProfileEventsPage />,
			},
			{
				path: ROUTES.editProfileInvestmentExpertise(),
				element: <EditProfileInvestmentExpertisePage />,
			},
			{
				path: ROUTES.editProfileTags(),
				element: <SelectTagsPage />,
			},
			{
				path: ROUTES.editProfileExpertise(),
				element: <EditProfileExpertisePage />,
			},
			{
				path: ROUTES.editProfileInterests(),
				element: <EditProfileInterestsPage />,
			},
			{
				path: ROUTES.editProfileInformation(),
				element: <EditProfileInformationPage />,
			},
			{
				path: ROUTES.editContact(),
				element: <EditContactPage />,
			},
			{
				path: ROUTES.editDietaryRestrictions(),
				element: <EditDietaryRestrictionsPage />,
			},
			{
				path: ROUTES.searchResults(),
				element: (
					<BackLinkPageWrapper backButtonType="arrow">
						<SearchResultsPage />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.reportContent(),
				element: <ReportContentPage />,
			},
			{
				path: ROUTES.addPost(),
				element: <PostCreationPage />,
			},
			{
				path: ROUTES.editPost(),
				element: <PostCreationPage />,
			},
			{
				path: ROUTES.post(),
				element: <PostPage />,
			},
			{
				path: ROUTES.supportFaq(),
				element: <SupportFaqPage />,
			},
			{
				path: ROUTES.supportAppTutorials(),
				element: <SupportAppTutorialsPage />,
			},
			{
				path: ROUTES.supportRequestHelp(),
				element: <SupportRequestHelpPage />,
			},

			// Admin routes.

			{
				element: <RestrictedRoutesWrapper allowForAdmin />,
				children: [
					{
						path: ROUTES.newPost(),
						element: (
							<Suspense fallback={loadingFallbackElement}>
								<NewPostPage />
							</Suspense>
						),
					},
					{
						path: ROUTES.postSettings(),
						element: (
							<Suspense fallback={loadingFallbackElement}>
								<PostSettingsPage />
							</Suspense>
						),
					},
				],
			},

			// 'ProfileChair' routes.

			{
				element: <RestrictedRoutesWrapper profileType="ProfileChair" />,
				children: [
					{
						path: ROUTES.meetingsRequests(),
						element: (
							<Suspense fallback={loadingFallbackElement}>
								<BackLinkPageWrapper backButtonType="arrow">
									<MeetingAttendanceRequestsPage />
								</BackLinkPageWrapper>
							</Suspense>
						),
					},
				],
			},

			// 'Deals routes',
			{
				path: ROUTES.newDeal(),
				element: <NewDealPage />,
			},
			{
				path: ROUTES.editDeal(),
				element: <EditDealPage />,
			},
			{
				path: ROUTES.dealTabs(),
				element: <DealTabsPage />,
				children: [],
			},
			{
				path: '/deal/:dealId',
				element: <RedirectToDealTab />,
			},
			{
				path: '/deal-filters/:communityId',
				element: <DealFiltersPage />,
			},
			{
				path: '/all-deal-filters',
				element: <AllDealFiltersPage />,
			},
			{
				path: ROUTES.dealSettings(),
				element: (
					<BackLinkPageWrapper backButtonType="arrow">
						<DealsSettingsPage />
					</BackLinkPageWrapper>
				),
			},
			{
				path: ROUTES.deals(),
				element: <DealsRootPage />,
				children: [
					{
						path: ROUTES.dealsTabs(),
						element: <DealsTabsPage />,
					},
				],
			},

			// Network Connections routes
			{
				path: ROUTES.createNetworkConnection(),
				element: <CreateNetworkConnectionPage />,
			},
			{
				path: ROUTES.editNetworkConnection(),
				element: <EditNetworkConnectionPage />,
			},
			{
				path: ROUTES.networkConnection(),
				element: <NetworkConnectionTabsPage />,
			},
			{
				path: ROUTES.networkConnectionFilters(),
				element: <NetworkConnectionFiltersPage />,
			},
		],
	},
]);
