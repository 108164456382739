import { Button } from '@ui-kit';
import { BackIcon, LampOffIcon } from '@ui-kit/icons';
import { useTranslation } from '@hooks';
import { clsx } from '@utils';
import styles from './TipNoMatchFoundState.module.css';

const TipNoMatchFoundState = ({ onGoBack, onCreateTipManually }: ITipNoMatchFoundStateProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.tipNoMatchFoundState}>
			<LampOffIcon className={styles.tipNoMatchFoundState__lampOffIcon} height={87} width={75} />
			<h2>{t('No Match Found')}</h2>
			<span className={styles.tipNoMatchFoundState__message}>
				{t('Go back and change your input, or proceed to create a Tip manually.')}
			</span>
			<div className={styles.tipNoMatchFoundState__buttonSet}>
				<Button
					className={styles.tipNoMatchFoundState__goBackIcon}
					icon={
						<BackIcon
							width={13}
							height={14}
							//
						/>
					}
					iconPosition="left"
					title={t('Go Back')}
					type="grey"
					variant="medium"
					onClick={onGoBack}
				/>
				<Button title={t('Create Manually in 3...')} type="outline" variant="medium" onClick={onCreateTipManually} />
			</div>
		</div>
	);
};

export interface ITipNoMatchFoundStateProps {
	onGoBack: () => void;
	onCreateTipManually: () => void;
}

export default TipNoMatchFoundState;
