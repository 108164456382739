import { Page } from '../../components';
import { ActionListItem, ActivityIndicator, EmptyStateMessage, Gap, PlusIcon, When } from '@ui-kit';
import { SimplePageLayout } from '../../components/SimplePageLayout/SimplePageLayout';
import TipCard from '@ui-modules/tipJar/components/TipCard';
import TipNoMatchFoundState from '@ui-modules/tipJar/components/TipNoMatchFoundState';
import { useTranslation, useNavigate } from '@hooks';
import { useTipMatchesQuery } from '@ui-modules/tipJar/hooks/useTipMatchesQuery';
import { useAiTipsQuery } from '@ui-modules/tipJar/hooks/useAiTipsQuery';
import { useTipFormSearchParams } from '@ui-modules/tipJar/hooks/useTipFormSearchParams';
import { compact } from '@utils';
import { ROUTES } from '@constants';
import styles from './NewTipMatchesPage.module.css';

const NewTipMatchesPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipFormParamsValidationError, tipFormValues] = useTipFormSearchParams();
	const tipMatchesQuery = useTipMatchesQuery(tipFormValues);
	const aiTipsQuery = useAiTipsQuery(tipFormValues);

	const nothingFound = !tipMatchesQuery.data?.length && !aiTipsQuery.data?.length;

	return (
		<Page title={t('Select Match')}>
			<SimplePageLayout headerTitle={nothingFound ? t('No Match Found') : t('Select Match')} scrollEnabled>
				<div className={styles.newTipMatchesPage__pageContent}>
					{tipMatchesQuery.isLoading ? (
						<ActivityIndicator type="fit" />
					) : tipMatchesQuery.isError || tipFormParamsValidationError ? (
						<EmptyStateMessage
							text={
								tipMatchesQuery.error?.message || tipFormParamsValidationError?.message || t('Something went wrong')
							}
						/>
					) : nothingFound ? (
						<TipNoMatchFoundState
							onCreateTipManually={() => navigate(ROUTES.createTip(tipFormValues))}
							onGoBack={() => navigate(-1)}
						/>
					) : (
						<>
							<h2>{t('We found these matches..')}</h2>
							<Gap gap={16} />

							<p className={styles.newTipMatchesPage__description}>
								{t('Select the one that most closely matches your Tip or create your own.')}
							</p>

							<Gap gap={16} />
							<div className={styles.newTipMatchesPage__tipMatchesList}>
								{tipMatchesQuery.data.map((tipMatch, index) => {
									return (
										<TipCard
											key={tipMatch.id + index}
											tip={tipMatch}
											onClick={() => {
												navigate(ROUTES.createTipReview({ tipId: tipMatch.id }));
											}}
										/>
									);
								})}
								{aiTipsQuery.isLoading ? (
									<>
										<div className={styles.newTipMatchesPage__loadingCard}>
											<span className={styles.newTipMatchesPage__loadingCardLabel}>{t('AI finding matches...')}</span>
										</div>
										{Array.from({ length: 5 }).map((_, index) => (
											<TipCard.Skeleton key={`TipCard.Skeleton-${index}`} />
										))}
									</>
								) : aiTipsQuery.isSuccess ? (
									aiTipsQuery.data.map((aiTip, index) => (
										<TipCard
											key={index}
											tip={aiTip}
											onClick={() => {
												navigate(
													ROUTES.createTip({
														...tipFormValues,
														...aiTip,
														images: compact([
															aiTip.images?.find((image) => image.source === 'ai'),
															tipFormValues.images?.find((image) => image.source === 'user'),
														]),
													}),
												);
											}}
										/>
									))
								) : null}
								<When condition={!aiTipsQuery.isLoading}>
									<ActionListItem
										Icon={<PlusIcon fill={'black'} height={24} width={24} />}
										note={t('Don’t see your match?')}
										title={t('Manually Create a Tip')}
										onClick={() => navigate(ROUTES.createTip(tipFormValues))}
									/>
									<p className={styles.newTipMatchesPage__description}>
										{t('All results have loaded. Got no match? Feel free to go back and alter your input.')}
									</p>
								</When>
							</div>
						</>
					)}
					<Gap gap={24} />
				</div>
			</SimplePageLayout>
		</Page>
	);
};

export interface INewTipMatchesPageParams extends Record<string, string | undefined> {
	id: string;
}

export default NewTipMatchesPage;
